<template>
  <a-layout class="custom-layout">
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="content-title auto">
          <div class="coustom-title fl">资讯发布</div>
          <div class="coustom-title-mes fr">
            <a-icon type="info-circle" theme="filled" style="color:#1890FF;margin-right: 10px;" />当前发布状态：正在发布 {{msg.active || 0}} 条，排队中 {{msg.waitfor || 0}} 条，已失效 {{msg.invalid || 0}} 条
          </div>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5" @click="addHandle"> 添加资讯 </a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
            <span slot="tableindex" slot-scope="text,record,index">{{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}</span>
            <div slot="release_status" slot-scope="text">
              <span v-if="text === 'active'">
                正在发布
              </span>
              <span v-if="text === 'waitfor'">
                等待发布
              </span>
              <span v-if="text === 'invalid'">
                已失效
              </span>
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button size="small" type="primary" @click="editHandle(record)" class="m-r-5">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="disabledOff(record)"
              >
                <a-button type="primary" size="small" class="bgred borred">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5" @click="addHandle"> 添加资讯 </a-button>
            </div>
          </custom-page>
        </div>
      </a-spin>
    </a-layout-content>

    <a-modal v-model="addVisible" title="资讯详情" @cancel="addClose" @ok="addSubmit" width="900px">
      <div class="add-top">
        <a-radio-group button-style="solid" size="small" v-model="newsType">
          <a-radio-button value="a">
            新闻和图片
          </a-radio-button>
          <a-radio-button value="b">
            仅图片
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="m-t-20" v-if="newsType === 'a'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          class="ant-advanced-search-form"
          :label-col="{span: 5, offset: 0}"
          :wrapper-col="{span: 17, offset: 0}"
          id="news-margin-form"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="标题" prop="news_title">
                <a-input placeholder="请输入标题" v-model="form.news_title" :suffix="titleTips" size="small"></a-input>
              </a-form-model-item>
              <a-form-model-item label="内容" prop="news_summary">
                <div class="summary-box auto">
                  <a-input type="textarea" v-model="form.news_summary" placeholder="请输入内容" size="small" style="height:96px;"></a-input>
                  <span>{{summaryTips}}</span>
                </div>
              </a-form-model-item>
              <a-form-model-item label="链接" prop="news_url">
                <a-input placeholder="请粘贴新闻原始链接" v-model="form.news_url" size="small"></a-input>
              </a-form-model-item>
              <a-form-model-item label="有效期" prop="time" style="margin-bottom: 0;">
                <a-range-picker
                  v-model="form.time"
                  :show-time="{ format: 'YYYY-MM-DD HH:mm:ss' }"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :placeholder="['开始时间', '结束时间']"
                  size="small"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{span: 20, offset: 4}" class="custom-news-item">
                <span class="font12">不选择时，系统默认发布时间为当前，失效时间为当前时间+3天</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="背景图片" prop="news_picture">
                <div class="img-box auto">
                  <img :src="form.news_picture" v-if="form.news_picture">
                </div>
              </a-form-model-item>
              <a-form-model-item label="" :wrapper-col="{span: 19, offset: 5}" style="margin-bottom: 0;">
                <!-- <a-button size="small">本地上传</a-button> -->
                <a-upload
                  name="file"
                  :multiple="true"
                  :headers="headers"
                  :transform-file="transformFile"
                  accept="image/*"
                  :showUploadList="false"
                >
                  <a-button size="small">本地上传</a-button>
                </a-upload>
              </a-form-model-item>
              <a-form-model-item label="" :wrapper-col="{span: 19, offset: 5}" class="custom-news-item">
                <span class="font12">建议尺寸为1920*1080px，其他尺寸将会被缩放或拉伸</span>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="m-t-20" v-else>
        <a-form-model
          ref="ruleForm2"
          :model="form2"
          :rules="rules2"
          class="ant-advanced-search-form"
          :label-col="{span: 7, offset: 0}"
          :wrapper-col="{span: 17, offset: 0}"
          id="no-margin-form"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="背景图片" prop="news_picture">
                <div class="img-box auto">
                  <img :src="form2.news_picture" v-if="form2.news_picture">
                </div>
              </a-form-model-item>
              <a-form-model-item label="" :wrapper-col="{span: 17, offset: 7}" style="margin-bottom: 0;">
                <!-- <a-button size="small" class="m-r-5">本地上传</a-button> -->
                <a-upload
                  name="file"
                  :multiple="true"
                  :headers="headers"
                  :transform-file="transformFile2"
                  accept="image/*"
                  :showUploadList="false"
                >
                  <a-button size="small" class="m-r-5">本地上传</a-button>
                </a-upload>
                <span class="font12">建议尺寸为1920*1080px，其他尺寸将会被缩放或拉伸</span>
              </a-form-model-item>
              <a-form-model-item label="有效期" prop="time" style="margin-bottom: 0;">
                <a-range-picker
                  v-model="form2.time"
                  :show-time="{ format: 'YYYY-MM-DD HH:mm:ss' }"
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :placeholder="['开始时间', '结束时间']"
                  size="small"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-model-item>
              <a-form-model-item label="" :wrapper-col="{span: 17, offset: 7}" class="custom-news-item">
                <span class="font12">不选择时，系统默认发布时间为当前，失效时间为当前时间+3天</span>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </a-layout>
</template>

<script>
import { News } from '@/model'
export default {
  data () {
    return {
      headers: {
        authorization: 'authorization-text',
      },
      loading: true,
      searchFilters: {},
      logBarsStatus: false,
      filters: {},
      filter: {
        current_page: 1,
        page_size: 10
      },
      msg: {
        active: 0,
        waitfor: 0,
        invalid: 0
      },
      count: 0,
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'tableindex',
          scopedSlots: { customRender: 'tableindex' },
          width: 70
        },
        {
          title: '标题',
          dataIndex: 'news_title'
        },
        {
          title: '发布状态',
          dataIndex: 'release_status',
          scopedSlots: { customRender: 'release_status' }
        },
        {
          title: '发布时间',
          dataIndex: 'release_time_from'
        },
        {
          title: '失效时间',
          dataIndex: 'release_time_to'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: '150px'
        }
      ],
      tabledata: [],
      addVisible: false,
      newsType: 'a',
      form: {
        news_title: '',
        news_summary: '',
        news_url: '',
        news_picture: '',
        time: []
      },
      rules: {
        news_title: [
          {required: true, message:'请输入标题！', trigger: 'blur'}
        ],
        news_picture: [
          {required: true, message:'请上传图片！', trigger: 'blur'}
        ],
        news_summary: [
          {required: true, message:'请输入内容！', trigger: 'blur'}
        ]
      },
      form2: {
        news_picture: '',
        time: []
      },
      rules2: {
        news_picture: [
          {required: true, message:'请上传图片！', trigger: 'blur'}
        ]
      },
      titleTips: '0/30',
      summaryTips: '0/100',
      isAdd: true,
      current_row: {}
    }
  },
  watch: {
    'form.news_title': function (val) {
      if (val.length > 30) {
        this.form.news_title = val.substring(0, 30)
      }
      this.titleTips = val.length + '/30'
    },
    'form.news_summary': function (val) {
      if (val.length > 100) {
        this.form.news_summary = val.substring(0, 100)
      }
      this.summaryTips = val.length + '/100'
    }
  },
  methods: {
    async editHandle (row) {
      this.addVisible = true
      this.isAdd = false
      this.current_row = row
      let res = await News.getNewsDetail(row.id)
      if (!res['code']) {
        if (res['data']['news_title']) {
          this.newsType = 'a'
          this.form = {
            news_title: res['data']['news_title'],
            news_summary: res['data']['news_summary'],
            news_url: res['data']['news_url'],
            news_picture: 'data:image/jpeg;base64,' + res['data']['news_picture'],
            time: res['data']['release_time_from'] ? [res['data']['release_time_from'], res['data']['release_time_to']] : []
          }
        } else {
          this.newsType = 'b'
          this.form2 = {
            news_picture: 'data:image/jpeg;base64,' + res['data']['news_picture'],
            time: res['data']['release_time_from'] ? [res['data']['release_time_from'], res['data']['release_time_to']] : []
          }
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async disabledOff (row) {
      let res = await News.deleteNews(row.id)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addHandle () {
      this.addVisible = true
      this.isAdd = true
    },
    addClose () {
      this.addVisible = false
      this.form = {
        news_title: '',
        news_summary: '',
        news_url: '',
        news_picture: '',
        time: []
      }
      this.form2 = {
        news_picture: '',
        time: []
      }
      this.newsType = 'a'
      this.$nextTick(() => {
        this.$refs['ruleForm'] && this.$refs['ruleForm'].resetFields()
        this.$refs['ruleForm2'] && this.$refs['ruleForm2'].resetFields()
      })
    },
    addSubmit () {
      if (this.newsType === 'a') {
        this.$refs['ruleForm'].validate( async validate => {
          if (validate) {
            let form = {
              news_title: this.form.news_title,
              news_summary: this.form.news_summary,
              news_url: this.form.news_url,
              release_time_from: this.form.time && this.form.time[0] ? this.form.time[0] : '',
              release_time_to: this.form.time && this.form.time[0] ? this.form.time[1] : '',
              news_picture: this.form.news_picture.split(',')[1]
            }
            let res = null
            if (!this.isAdd) {
              res = await News.changeNews(this.current_row['id'], form)
            } else {
              res = await News.saveNews(form)
            }
            if (!res['code']) {
              this.$message.success('保存成功！')
              this.addClose()
              this.getList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          }
        })
      } else {
        this.$refs['ruleForm2'].validate( async validate => {
          if (validate) {
            let form = {
              release_time_from: this.form2.time && this.form2.time[0] ? this.form2.time[0] : '',
              release_time_to: this.form2.time && this.form2.time[0] ? this.form2.time[1] : '',
              news_picture: this.form2.news_picture.split(',')[1]
            }
            let res = null
            if (!this.isAdd) {
              res = await News.changeNews(this.current_row['id'], form)
            } else {
              res = await News.saveNews(form)
            }
            if (!res['code']) {
              this.$message.success('修改成功！')
              this.addClose()
              this.getList()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          }
        })
      }
    },
    transformFile (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.news_picture = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    transformFile2 (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form2.news_picture = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    getPageMes (obj) {
      this.filter = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.getList()
    },
    async getList () {
      this.loading = true
      let res = await News.getList(this.filter)
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
      let res2 = await News.getCount()
      if (!res2['code']) {
        this.msg = res2['data'] || {}
      } else {
        // this.$message.error(res2['description'])
        this.$message.error(this.errMsg[res2['code']])
      }
    },
    onClose () {
      this.visible = false
    }
  },
  created () {
    this.getList()
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}

.add-top {
  text-align: center;
}
.img-box {
  border: 1px dashed rgba(0,0,0,0.15);
  border-radius: 2px;
  width: 317px;
  height: 194px;
}
.img-box img {
  width: 100%;
  height: 100%;
}

.summary-box {
  position: relative;
}
.summary-box span {
  position: absolute;
  right: 10px;
  bottom: 0;
}
</style>
